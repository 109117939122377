import React, { useState } from "react";


import { navigate } from "gatsby";

import HomeButton from "../../components/common/Buttons/Home";
import PrimaryButton from "../../components/common/Buttons/Primary";
import BaseInput from "../../components/common/Inputs/BaseInput";
import PasswordInput from "../../components/common/Inputs/Password";
import { useLogin } from "../../hooks/auth/useLogin";

const initialState = {
  email: "",
  password: "",
  error: "",
};

const LoginEmailPage: React.FunctionComponent = () => {
  const [form, setForm] = useState(initialState);

  const { loginByEmail } = useLogin();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    loginByEmail(form.email, form.password);
    navigate("/");
  };

  return (
    <div className="container h-screen flex justify-center">
      <div className="p-8 bg-white rounded-lg bg-gray-100 pb-10">
        <header className="border bg-blue-400 lg:text-3xl text-4xl text-white dark:white font-bold">
          Email Login
        </header>
        <br />
        <form onSubmit={handleSubmit}>
          <BaseInput
            id="email"
            name="email"
            title="email"
            type="email"
            value={form.email}
            placeholder="Email"
            onChange={onChange}
          />

          <PasswordInput
            id="password"
            name="password"
            title="pass"
            value={form.password}
            placeholder="Password"
            onChange={onChange}
          />

          <br />
          <PrimaryButton disabled={false} type="submit">
            Login
          </PrimaryButton>
          <HomeButton />
        </form>
      </div>
    </div>
  );
};

export default LoginEmailPage;
